import React, { useState, useEffect } from "react";
import MyNavbar from "../Navbar/navbar";
import "../Dashboard/Home.css";
import Footer from "../Footer/Footer";
import Courses from "../Courses/Courses";
import Banner from "../Banner/Banner";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap Modal
import "bootstrap/dist/css/bootstrap.min.css";  // Import Bootstrap CSS
import axios from "axios"; // Import axios

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalTimer, setModalTimer] = useState(null);

  // Function to show modal
  const showModalWithDelay = () => {
    if (!localStorage.getItem("formSubmitted")) {
      setModalTimer(
        setTimeout(() => {
          setShowModal(true);
        }, 5000) // Set modal to show again after 5 seconds
      );
    }
  };

  useEffect(() => {
    // Show the modal when the component mounts for the first time
    showModalWithDelay();

    return () => {
      // Cleanup the timer when the component is unmounted or the modal closes
      clearTimeout(modalTimer);
    };
  }, []);

  const handleClose = () => {
    setShowModal(false);
    // Set the modal to reappear after a certain time if not submitted
    showModalWithDelay();
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    localStorage.setItem("formSubmitted", "true"); // Mark the form as submitted in localStorage
  };

  const handleErrorClose = () => {
    setShowErrorModal(false);
    // Optionally, you could show the main modal again after a delay
    showModalWithDelay();
  };

  // Form submission handler using axios
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loading indicator

    const formData = new FormData(event.target);
    formData.append("access_key", "00061cdc-a251-4c2b-b804-e9004c6a2b66");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await axios.post("https://api.web3forms.com/submit", json, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (res.data.success) {
        setShowModal(false); // Close the main modal
        setShowSuccessModal(true); // Show success modal
      } else {
        setShowErrorModal(true); // Show error modal
      }
    } catch (error) {
      console.error("Form submission error:", error);
      setShowErrorModal(true); // Show error modal
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  return (
    <>
      <MyNavbar />
      <Banner />
      <br />
      <Courses />
      <br></br>
      <Footer /> 

      {/* Main Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form inside the Modal */}
          <form onSubmit={onSubmit}>
            <input type="text" name="name" placeholder="Enter your name" className="data" required />
            <input type="email" name="email" placeholder="Enter your email" className="data" required />
            <input type="number" name="phone" placeholder="Enter your phone number" className="data" required />
            
            {/* Dropdown options */}
            <select name="course" className="data" required>
              <option value="">Select a course</option>
              <option value="MS Access">MS Access</option>
              <option value="Oracle">Oracle</option>
              <option value="MySQL">MySQL</option>
              <option value="Programming Languages (Any one)">Programming Languages (Any one)</option>
              <option value="Diploma in Software Development">Diploma in Software Development</option>
              <option value="Graphic Designing with Digital Marketing">Graphic Designing with Digital Marketing</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Graphic Designing with Video Editing">Graphic Designing with Video Editing</option>
              <option value="Graphic Designing">Graphic Designing</option>
              <option value="Tally ERP/ Prime (with GST)">Tally ERP/ Prime (with GST)</option>
              <option value="Advance Excel">Advance Excel</option>
              <option value="Accounting Professional">Accounting Professional</option>
            </select>

            <textarea name="message" placeholder="Your message" className="data" required></textarea>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? "Submitting..." : "Submit Form"}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your details have been successfully submitted!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={handleErrorClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Failed to send your details. Please try again later.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleErrorClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Home;
