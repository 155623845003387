import React from "react";
import "../privacy-policy/privacy.css";
import MyNavbar from "../../Navbar/navbar";
import Footer from "../../Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <MyNavbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="privacypolicy">
        <p className="privacyDate">7/10/24, 3:45 PM</p>
        <h3>PRIVACY POLICY</h3>
        <h6> Last updated July 10, 2024</h6>
        <p>
          This privacy notice for vanija academy ('we', 'us', or 'our'),
          describes how and why we might collect, store, use, and/or share
          ('process') your information when you use our services ('Services'),
          such as when you:
          <li>
            Visit our website at{" "}
            <a href="https://www.vanijaacademy.com">
              https://www.vanijaacademy.com
            </a>
            , or any website of ours that links to this privacy notice{" "}
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
          <b> Questions or concerns?</b> Reading this privacy notice will help
          you understand your privacy rights and choices. If you do not agree
          with our policies and practices, please do not use our Services. If
          you still have any questions or concerns, please contact us at
          hr@vanijaacademy.com.
        </p>
        <h3>SUMMARY OF KEY POINTS</h3>
        <p>
          <i>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for. What personal
            information do we process? When you visit, use, or navigate our
            Services, we may process personal information depending on how you
            interact with us and the Services, the choices you make, and the
            products and features you use. Learn more about personal information
            you disclose to us. Do we process any sensitive personal
            information? We may process sensitive personal information when
            necessary with your consent or as otherwise permitted by applicable
            law. Learn more about sensitive information we process.
          </i>
        </p>
        <b>What personal information do we process?</b>
        <p>
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about personal information you disclose to us.
        </p>
        <b>Do we process any sensitive personal information?</b>
        <p>
          We may process sensitive personal information when necessary with your
          consent or as otherwise permitted by applicable law. Learn more about
          sensitive information we process.
        </p>
        <h6>
          Do we collect any information from third parties? We do not collect
          any information from third parties.
        </h6>
        <h4>How do we process your information?</h4>
        <p>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about how we process
          your information.
        </p>
        <h4>
          In what situations and with which parties do we share personal
          information?
        </h4>
        <p>
          We may share information in specific situations and with specific
          third parties. Learn more about when and with whom we share your
          personal information.
        </p>
        <h4>How do we keep your information safe?</h4>
        <p>
          We have organisational and technical processes and procedures in place
          to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorised third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about how we keep your
          information safe.
        </p>
        <h4>What are your rights?</h4>
        <p>
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information. Learn more about your privacy rights.
        </p>
        <h4>How do you exercise your rights?</h4>
        <p>
          The easiest way to exercise your rights is by submitting a data
          subject access request, or by contacting us. We will consider and act
          upon any request in accordance with applicable data protection laws.
        </p>
        <h6>
          Want to learn more about what we do with any information we collect?
          Review the privacy notice in full.
        </h6>
        <h3>TABLE OF CONTENTS</h3>
        <ol type="1">
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
          <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
        </ol>
        <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
        <h4>Personal information you disclose to us</h4>
        <b>In Short:</b>
        <p>We collect personal information that you provide to us. </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you express an interest in obtaining information about us or our
          products and Services, when you participate in activities on the
          Services, or otherwise when you contact us.
        </p>
        <b>Personal Information Provided by You</b>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <ul typeof="circle">
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>contact preferences</li>
          <li>contact or authentication data</li>
        </ul>
        <b>Sensitive Information</b>
        <p>
          When necessary, with your consent or as otherwise permitted by
          applicable law, we process the following categories of sensitive
          information:
        </p>
        <li>biometric data</li>
        <li>student data</li>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
        <b>In Short:</b>
        <p>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent.
        </p>
        <b>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </b>
        <li>
          To post testimonials. We post testimonials on our Services that may
          contain personal information.
        </li>
        <li>
          To deliver targeted advertising to you. We may process your
          information to develop and display personalised content and
          advertising tailored to your interests, location, and more
        </li>
        <li>
          To request feedback. We may process your information when necessary to
          request feedback and to contact you about your use of our Services.
        </li>
        <h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
        <b>In Short:</b>
        <p>
          We may share information in specific situations described in this
          section and/or with the following third parties.
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <li>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>
        <li>
          When we use Google Maps Platform APIs. We may share your information
          with certain Google Maps Platform APIs (e.g. Google Maps API, Places
          API).
        </li>
        <h3>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h3>
        <b>In Short:</b>
        <p>
          We are not responsible for the safety of any information that you
          share with third parties that we may link to or who advertise on our
          Services, but are not affiliated with, our Services.
        </p>
        <p>
          The Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee
          regarding any such third parties, and we will not be liable for any
          loss or damage caused by the use of such third-party websites,
          services, or applications. The inclusion of a link towards a
          third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties
          is not covered by this privacy notice. We are not responsible for the
          content or privacy and security practices and policies of any third
          parties, including other websites, services, or applications that may
          be linked to or from the Services. You should review the policies of
          such third parties and contact them directly to respond to your
          questions.
        </p>
        <h3>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
        <b>In Short:</b>
        <p>
          We may use cookies and other tracking technologies to collect and
          store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services, prevent crashes, fix bugs, save your preferences, and assist
          with basic site functions.
        </p>
        <p>
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </p>
        <p>
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </p>
        <h4>Google Analytics</h4>
        <p>
          We may share your information with Google Analytics to track and
          analyse the use of the Services. The Google Analytics Advertising
          Features that we may use include: Google Display Network Impressions
          Reporting. To opt out of being tracked by Google Analytics across the
          Services, visit https://tools.google.com/dlpage/gaoptout. You can opt
          out of Google Analytics Advertising Features through
          <a href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings">
            Ads Settings
          </a>{" "}
          and Ad Settings for mobile apps. Other opt out means include
          <a href="http://optout.networkadvertising.org/">
            http://optout.networkadvertising.org/
          </a>{" "}
          and{" "}
          <a href="http://www.networkadvertising.org/mobilechoice">
            http://www.networkadvertising.org/mobilechoice
          </a>
          . For more information on the privacy practices of Google, please
          visit the
          <a href="https://policies.google.com/privacy">
            Google Privacy & Terms page.
          </a>
        </p>
        <h3>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
        <b>In Short:</b>
        <p>
          : We keep your information for as long as necessary to fulfil the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than 90
          days.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h3>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
        <b>In Short:</b>
        <p>
          We aim to protect your personal information through a system of
          organisational and technical security measures.
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
        <b>In Short:</b>
        <p>
          You may review, change, or terminate your account at any time,
          depending on your country, province, or state of residence.
        </p>
        <p>
          <u>
            <b>Withdrawing your consent:</b>
          </u>
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section 'HOW CAN YOU CONTACT
          US ABOUT THIS NOTICE?' below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <b>
          <u>Cookies and similar technologies: </u>
        </b>
        <p>
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. You
          may also{" "}
          <a href="https://optout.aboutads.info/?c=2&lang=EN">
            opt out of interest based advertising by advertisers{" "}
          </a>
          on our Services.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at hr@vanijaacademy.com.
        </p>
        <br></br>
        <br></br>
        <h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h3>10. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
        <b>In Short:</b>
        <p>
          You may have additional rights based on the country you reside in.
        </p>
        <b>Australia</b>
        <p>
          We collect and process your personal information under the obligations
          and conditions set by Australia's Privacy Act 1988 (Privacy Act).
        </p>
        <p>
          This privacy notice satisfies the notice requirements defined in the
          Privacy Act, in particular: what personal information we collect from
          you, from which sources, for which purposes, and other recipients of
          your personal information.
        </p>
        <p>
          If you do not wish to provide the personal information necessary to
          fulfil their applicable purpose, it may affect our ability to provide
          our services, in particular:
        </p>
        <li>offer you the products or services that you want</li>
        <li>respond to or help with your requests</li>
        <p>
          At any time, you have the right to request access to or correction of
          your personal information. You can make such a request by contacting
          us by using the contact details provided in the section 'HOW CAN YOU
          REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'
        </p>
        <p>
          If you believe we are unlawfully processing your personal information,
          you have the right to submit a complaint about a breach of the
          Australian Privacy Principles to the{" "}
          <a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us">
            Office of the Australian Information Commissioner .
          </a>
        </p>
        <h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
        <b>In Short:</b>
        <p>
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date at the top of
          this privacy notice. If we make material changes to this privacy
          notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this privacy notice frequently to be informed of how we
          are protecting your information.
        </p>
        <h3>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
        <p>
          If you have questions or comments about this notice, you may email us
          at hr@vanijaacademy.com or contact us by post at:
        </p>
        <strong>
          <i>
            vanija academy <br />
            Vaneeja tower, A-1/30 ,hilori market,nandri road, Banar Rd, Jodhpur,
            Rajasthan 342001 <br /> Jodhpur 342027 <br /> Rajasthan <br /> India
          </i>
        </strong>
        <h3>
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h3>
        <p>
          You have the right to request access to the personal information we
          collect from you, details about how we have processed it, correct
          inaccuracies, or delete your personal information. You may also have
          the right to withdraw your consent to our processing of your personal
          information. These rights may be limited in some circumstances by
          applicable law. To request to review, update, or delete your personal
          information, please fill out and submit a{" "}
          <a href="/Privacy-Policy">data subject access request.</a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
