/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import imgss from "../Images/logo.png";
import "../Navbar/nav.css";
import { Button, Offcanvas } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { NavDropdown, Container, Form, FormControl } from "react-bootstrap";
import instagram from "../Images/icons/instagram.png";
import facebook from "../Images/icons/facebook.png";
// import twitter from "../Images/icons/twitter.png";
import whatsapp from "../Images/icons/whatsapp.png";

const MyNavbar = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  return (
    <>
      <Navbar bg="body-tertiary" fixed="top">
        <Container fluid>
          <a href="/">
            <img src={imgss} alt="Vanija Academy Logo" className="logo-image" />
          </a>
          <div className="navtext">
            <Nav.Link href="/" className="nav-link active">
              Home
            </Nav.Link>
            {/* <Nav.Link href="#" className="nav-link">
              Training-Program
            </Nav.Link> */}
            <Nav.Link href="#" className="nav-link">
              Courses Offer
            </Nav.Link>
            <Nav.Link href="#" className="nav-link">
              Our Team
            </Nav.Link>
            <Nav.Link href="/about" className="nav-link">
              About us
            </Nav.Link>
            <Nav.Link href="/Contact-us" className="nav-link">
              Contact Us
            </Nav.Link>
            <Nav.Link href="/Privacy-Policy" className="nav-link">
              Privacy-policy
            </Nav.Link>
            {/* <a
              target="_blank"
              href="https://www.instagram.com/vanija.academy/"
              rel="noreferrer"
            >
              <img src={instagram} id="icons" alt="vanija"></img>
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/vanijaedu/"
              rel="noreferrer"
            >
              <img src={facebook} id="icons" alt="vanija"></img>
            </a> */}
            {/* <a href="#"><img src={twitter} id="icons"></img></a> */}
            {/* <a
              href="#"
              target="_blank"
              href="https://api.whatsapp.com/send?phone=919257868737"
              rel="noreferrer"
            >
              <img src={whatsapp} id="icons" alt="vanija"></img>
            </a> */}
          </div>

          {/* <Navbar.Brand href="#">Offcanvas navbar</Navbar.Brand> */}
          <Button variant="outline-light" onClick={toggleOffcanvas}>
            <span className="navbar-toggler-icon"></span>
          </Button>
          <Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Vanija Academy</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Item>
                  <Nav.Link href="/" className="nav-link active">
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#" className="nav-link">
                    Training-Program
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <NavDropdown title="Courses Offer">
                    <NavDropdown.Item href="#">
                      Accounting Pofessional
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">Advance Excel</NavDropdown.Item>
                    <NavDropdown.Item href="#">
                      Tally ERP/ Prime (with GST)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">
                      Graphic Desigining
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">
                      Graphic designing with Video Editing
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">
                      Digital Marketing
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">
                      Diploma in Software Develpment
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">
                      Programming Languages (Any one)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#">DataBase</NavDropdown.Item>
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item href="#">
                      Something else here
                    </NavDropdown.Item> */}
                  </NavDropdown>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#" className="nav-link">
                    Our Team
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#" className="nav-link">
                    About us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/Contact-us" className="nav-link">
                    Contact Us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/Privacy-Policy" className="nav-link">
                    Privacy-Policy
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <NavDropdown title="Follow-us On">
                    {/* <NavDropdown.Item href="#">Accounting Pofessional</NavDropdown.Item> */}
                    <a
                      target="_blank"
                      href="https://www.instagram.com/vanija.academy/"
                      rel="noreferrer"
                    >
                      <img src={instagram} id="icons" alt="vanija"></img>{" "}
                      Instagram
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/vanijaedu/"
                      rel="noreferrer"
                    >
                      <img src={facebook} id="icons" alt="vanija"></img>{" "}
                      Facebook
                    </a>
                    {/* <a href="#"><img src={twitter} id="icons"></img></a> */}
                    <a
                      href="#"
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=919257868737"
                      rel="noreferrer"
                    >
                      <img src={whatsapp} id="icons" alt="vanija"></img>{" "}
                      whatsapp
                    </a>
                  </NavDropdown>
                </Nav.Item>
              </Nav>
              <Form className="d-flex mt-3" role="search">
                <FormControl
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                {/* <Button variant="outline-success" type="submit">
                  Search
                </Button> */}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default MyNavbar;
