// import React, { useState } from "react";
import "../Contact_us/contactUs.css"; // Optional: for custom styles
import MyNavbar from "../Navbar/navbar";
import sitedown from "../Images/site-down.jpg"

const ContactUs = () => {
  // const onSubmit = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);

  //   formData.append("access_key", "00061cdc-a251-4c2b-b804-e9004c6a2b66");

  //   const object = Object.fromEntries(formData);
  //   const json = JSON.stringify(object);

  //   const res = await fetch("https://api.web3forms.com/submit", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: json,
  //   }).then((res) => res.json());

  //   if (res.success) {
  //     console.log("Success", res);
  //   }
  // };

  return (
    <>
    <MyNavbar/>

    
    <div className="formdata">
      <img src={sitedown}></img>
    {/* <form onSubmit={onSubmit}>
      <input type="text" className="data"/>
      <input type="email" className="data"/>
      <input type="number" className="data" />
      
      <textarea name="message"></textarea>
      <button type="submit">Submit Form</button>
    </form> */}
    </div>
    </>
  );
};
export default ContactUs;
