import React from "react";
import MyNavbar from "../../Navbar/navbar";
// import aboutus from "../../Images/Courses/aboutus.png";
import "../AboutUs/aboutus.css"

export default function About_Us() {
  return (
    <>
      <MyNavbar/>
     {/* <div className="aboutsu">

     </div> */}
    </>
  );
}
