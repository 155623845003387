import React from "react";
// import MyNavbar from "../Navbar/navbar";
import bannerimage from "../Images/ban.jpg";
import "../Banner/banner.css";
// import Footer from "../Footer/Footer";
// import Courses from "../Courses/Courses";

export default function Banner() {
  return (
    <>
      <div className="banner">
        <img id="banner-Image" src={bannerimage} alt="" />
        <div className="test">
          <h1 className="logo-text">
            Empowering Careers Through Vanija Academy: Unlocking the Potential
            of IT Training
          </h1>
          <p className="logo-subtext">
            Discover Vaneeja Academy's mission and values in shaping future IT
            professionals through comprehensive training programs.
          </p>
          <button className="learnmorebtn"> Contact Us </button>
        </div>
      </div>
    </>
  );
}
