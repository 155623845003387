import React, { useState } from "react";
import "../Courses/courses.css";
import excel from "../Images/Courses/spreadsheet.jpg";
import grap from "../Images/Courses/GD-1.svg";
import digital from "../Images/Courses/digital-marketing-vector-illustration2.png";
import tally from "../Images/Courses/tally.jpg";
import account from "../Images/Courses/account.jpg";
import video from "../Images/Courses/video edit.jpg";
import software from "../Images/Courses/software.jpg";
import lang from "../Images/Courses/progran.jpg";
import db from "../Images/Courses/database.jpg";
// import Pu from "../Images/icons/understanding.png";

const courses = [
  {
    imgSrc: account,
    title: "Accounting Professional",
    text: "Manual Accounting, MS Office with Advance Options including Advance Excel, Tally ERP / Tally Prime using GST, Income Tax & GST in depth, TDS.....",
    updatedAt: "Duration 1 Month \nClasses: Offline Available",
  },
  {
    imgSrc: excel,
    title: "Advance Excel",
    text: "Basic Excel, Mathematical Formulas, Text Formulas, Statistical Formulas, Audit Formulas, Pivot Table, Filters, Macro, Recording, Conditional Formatting, Security Control.",
    updatedAt: "Duration 1 Month \nClasses: Offline Available",
  },
  {
    imgSrc: tally,
    title: "Tally ERP/ Prime (with GST)",
    text: "Manual Accounting, Inventory, Budget & Bills, Payroll, Voucher Entries, Accounting Master, Bank Reconciliations, Tally Software and Project Work.",
    updatedAt: "Duration 2 Month \nClasses: Offline Available",
  },
  {
    imgSrc: grap,
    title: "Graphic Designing",
    text: "Adobe Photoshop, CorelDRAW, Adobe Illustrator, 3 Mobile App (Canva, Polish, Text on Image).",
    updatedAt: "Duration 4 Month \nClasses: Offline/Online Available",
  },
  {
    imgSrc: video,
    title: "Graphic Designing with Video Editing",
    text: "Photoshop, Illustrator, Premiere Pro, After Effects.",
    updatedAt: "Duration 4 Month \nClasses: Offline/Online Available",
  },
  {
    imgSrc: digital,
    title: "Digital Marketing",
    text: "SEO, SMO, Content Marketing, Ecommerce Marketing, Google Analytics, AdWords, Marketing Strategies, Run Ads on Facebook, Instagram, Google Lead Generation, WordPress.",
    updatedAt: "Duration 4 Month \nClasses: Offline/Online Available",
  },
  {
    imgSrc: grap,
    title: "Graphic Designing with Digital Marketing",
    text: "Photoshop, Illustrator. || SEO, SMO, Content Marketing, Ecommerce Marketing, Google Analytics, AdWords, Marketing Strategies, Run Ads.",
    updatedAt: "Duration 4 Month \nClasses: Offline/Online Available",
  },
  {
    imgSrc: software,
    title: "Diploma in Software Development",
    text: "Web Design with HTML, CSS, JavaScript, Bootstrap, Programming with C & C++, Web development in Advanced Technologies Database & Major Project Work.",
    updatedAt: "Duration 8 Month \nClasses: Offline/Online Available",
  },
  {
    imgSrc: lang,
    title: "Programming Languages (Any one)",
    text: "Programming in C / OOPS with C++ / Core Java / Python.",
    updatedAt: "Duration 2 Month \nClasses: Offline/Online Available",
  },
  {
    imgSrc: db,
    title: "Database",
    text: "MS Access, Oracle, MySQL.",
    updatedAt: "Duration 2 Month \nClasses: Offline/Online Available",
  },
];

export default function Courses() {
  const [visibleCourses, setVisibleCourses] = useState(5); // Initially show 5 courses
  const [isExpanded, setIsExpanded] = useState(false); // Track whether all courses are shown

  const toggleCourses = () => {
    setVisibleCourses(isExpanded ? 5 : courses.length); // Show all or only 5 courses
    setIsExpanded(!isExpanded); // Toggle expanded state
  };

  const Card = ({ imgSrc, title, text, updatedAt }) => (
    <div
      className="card"
      onClick={() => {
      //   // Handle the click, e.g., navigate to a link or perform an action
      //   // console.log(Clicked on ${title});
      }}
    >
      <img src={imgSrc} className="card-img-top" alt={title} />
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{text}</p>
        <p className="card-text">
          <small className="text-body-secondary">{updatedAt}</small>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <div className="courses mt-3 pl-2">
        <div className="para">
          <h4>List of IT Corporate Training Courses</h4>
          <p>
            Explore our IT Training courses, tailored for professionals! Equip
            your team with cutting-edge skills for tomorrow's data-driven
            challenges.
          </p>
        </div>
        <div className="card-group">
          {courses.slice(0, visibleCourses).map((course, index) => (
            <a href="##" key={index}>
              <Card {...course} />
            </a>
          ))}
        </div>
        <div className="text-center mt-4">
          <button className="load-more" onClick={toggleCourses}>
            {isExpanded ? "Show Less" : "Explore More"} 
          </button>
        </div>
      </div>
      {/* <div className="para">
        <h4>Features</h4>
        <p>Because only taking a course is not enough to grab a dream job</p>
      </div> */}
    </>
  );
}