import { Box } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import "../Footer/footer.css";

const Footer = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        height: "70px",
        // width: "500px",
        bgcolor: "#021059",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box component="span" sx={{ color: palette.primary.main }}>
      <a href="/Privacy-Policy">  All rights © reserved!</a> To Vanija Acadmy 2024-25.
      </Box>
    </Box>
  );
};

export default Footer;
