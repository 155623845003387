  import "./App.css";
  import Home from "./Components/Dashboard/Home";
  import { BrowserRouter, Routes, Route } from "react-router-dom";
  import About from "./Components/Pages/AboutUs/aboutUs";
  import PrivacyPolicy from "./Components/Pages/privacy-policy/privacy-policy";
import ContactUs from "./Components/Contact_us/contactUs";

  function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Contact-us" element={<ContactUs />} />
          <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        
        </Routes>
      </BrowserRouter>
    );
  }

  export default App;
